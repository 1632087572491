import { QuickAnnouncement, QuickAnnouncementAction, SET_QUICK_ANNOUNCEMENTS } from './types';

const quickAnnouncementsReducer = (
  action: QuickAnnouncementAction,
  state: QuickAnnouncement[] = [],
): QuickAnnouncement[] => {
  switch (action.type) {
    case SET_QUICK_ANNOUNCEMENTS:
      return action.announcements;
    default:
      return state;
  }
};

export default quickAnnouncementsReducer;
