import React, { ReactNode } from 'react';

import Footer from './Footer';
import Header from './Header';

const Layout: React.FC<{ children: ReactNode }> = function getLayout({ children }) {
  return (
    <>
      {/* <QuickAnnouncementBar /> */}
      <Header />
      <main className="main-wrapper">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
