import { AuthAction, LOGOUT } from '../auth/types';
import { ProfileAction } from '../profile/types';
import {
  emptyQuizResponse,
  FINISH_QUESTION_QUIZ,
  FINISHING_QUESTION_QUIZ,
  LOAD_STYLE_QUIZ,
  RENDER_STYLE_QUIZ,
  StyleQuizAction,
  StyleQuizState,
  UPDATE_QUIZ_RESPONSE,
} from './types';

function styleQuizReducer(
  action: StyleQuizAction | AuthAction | ProfileAction,
  state: StyleQuizState = { loading: true, loaded: false, quiz_response: emptyQuizResponse },
): StyleQuizState {
  switch (action.type) {
    case LOAD_STYLE_QUIZ:
      return { loading: true, loaded: false, quiz_response: emptyQuizResponse };
    case RENDER_STYLE_QUIZ:
      return {
        ...state,
        loading: false,
        loaded: true,
        quiz: action.payload,
      };
    case UPDATE_QUIZ_RESPONSE:
      return {
        ...state,
        quiz_response: action.payload[0],
      };
    case FINISHING_QUESTION_QUIZ:
      return {
        ...state,
        finishing: true,
        finished: false,
        show_onboarding: action.shouldShowOnboarding,
      };
    case FINISH_QUESTION_QUIZ:
      return {
        ...state,
        finishing: false,
        finished: true,
      };
    case LOGOUT:
      if (state.loaded) {
        return {
          ...state,
          quiz_response: emptyQuizResponse,
        };
      }
      return state;
    default:
      return state;
  }
}

export default styleQuizReducer;
