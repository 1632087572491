import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import * as Sentry from '@sentry/react';
import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithCredential,
  signOut,
  User as FirebaseUser,
} from 'firebase/auth';
import {
  doc,
  DocumentSnapshot,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import { trackCompleteRegistration } from '../../app/analytics';
import { FirebaseInstance } from '../../app/FirebaseInstance';
import { setFirebaseAuthToken } from '../../middleware/service/api';
import { ProfileAction, SET_PROFILE, User } from '../profile/types';
import { AuthAction, LOGIN, LOGIN_ERROR, LOGIN_START, LOGOUT, UNAUTHORIZED_ERROR } from './types';

function getProfileFromSnap(snap: DocumentSnapshot<User>): User | null | undefined {
  if (snap && snap.data) {
    return snap.data();
  }
  return null;
}

export function handleProfileWatchResponse(
  dispatch: (type: AuthAction | ProfileAction) => void,
  fb: FirebaseInstance,
  userProfileSnap: DocumentSnapshot<User>,
) {
  const profile = getProfileFromSnap(userProfileSnap);
  setUserProperties(fb.analytics, { quiz_completed: profile?.isQuizCompleted });
  if (profile) {
    setUserId(fb.analytics, profile.id);
  }
  dispatch({
    type: SET_PROFILE,
    profile,
  });
}

export function unWatchUserProfile(fb: FirebaseInstance) {
  if (fb._.profileWatcher) {
    fb._.profileWatcher();
    fb._.profileWatcher = null;
  }
}

export function watchUserProfile(
  dispatch: (type: AuthAction | ProfileAction) => void,
  fb: FirebaseInstance,
) {
  unWatchUserProfile(fb);
  const { authUid } = fb._;

  fb._.profileWatcher = onSnapshot(doc(fb.firestore, 'users', authUid), (userProfileSnap: any) =>
    handleProfileWatchResponse(dispatch, fb, userProfileSnap),
  );
}

const handleAuthStateChange = (
  dispatch: (type: AuthAction | ProfileAction) => void,
  authData: FirebaseUser | null,
  fb: FirebaseInstance,
) => {
  if (!authData) {
    dispatch({
      type: 'AUTH_EMPTY_CHANGE',
    });
    unWatchUserProfile(fb);
    setUserProperties(fb.analytics, { logged_in: false });
  } else {
    fb._.authUid = authData.uid;
    dispatch({
      type: LOGIN,
      auth: authData,
    });
    setFirebaseAuthToken(fb);
    watchUserProfile(dispatch, fb);
    setUserProperties(fb.analytics, { logged_in: true });
  }
};

const createOrUpdateUserProfile = (
  dispatch: (type: AuthAction | ProfileAction) => void,
  user: FirebaseUser,
  fb: FirebaseInstance,
) => {
  getDoc(doc(fb.firestore, 'users', user.uid))
    .then((profileSnap) => {
      if (profileSnap.exists()) {
        updateDoc(profileSnap.ref, {
          updatedAt: serverTimestamp(),
        }).then(() => {
          // TODO: Add additional parameters (custom dimension or metrics)
          logEvent(fb.analytics, 'login', {
            method: 'Google',
          });
        });
      } else {
        const timestamp = serverTimestamp();
        setDoc(profileSnap.ref, {
          name: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          phoneNumber: user.phoneNumber,
          avatar: user.photoURL,
          provider: 'google',
          createdOn: timestamp,
          updatedAt: timestamp,
          isQuizCompleted: false,
          quizResponse: {},
        }).then(() => {
          logEvent(fb.analytics, 'sign_up', {
            method: 'Google',
          });
        });
      }
    })
    .catch(() => {
      dispatch({
        type: UNAUTHORIZED_ERROR,
        errorMsg: 'User is not authorized',
      });
    });
};

/**
 * Redirect result handler
 * @param {Function} dispatch - Action dispatch function
 * @param {object} firebase - Internal firebase object
 * @param  {object} authData - Auth data from Firebase's getRedirectResult
 * @returns {void}
 * @private
 */
// const handleRedirectResult = (
//   dispatch: (type: AuthAction | ProfileAction) => void,
//   fb: FirebaseInstance,
//   authData: firebase.auth.UserCredential,
// ) => {
//   if (authData && authData.user) {
//     const { user } = authData;
//     console.log('user details', user);

//     fb._.authUid = user.uid; // eslint-disable-line no-param-reassign

//     // watchUserProfile(dispatch, fb);

//     trackCompleteRegistration();
//     createOrUpdateUserProfile(dispatch, authData.user, fb);
//   }
// };

export const init = (
  dispatch: (type: AuthAction | ProfileAction) => void,
  fb: FirebaseInstance,
) => {
  onAuthStateChanged(fb.auth, (authData) => {
    handleAuthStateChange(dispatch, authData, fb);
  });

  // if (
  //   typeof firebase.auth().getRedirectResult === 'function' &&
  //   typeof window !== 'undefined' &&
  //   window.location &&
  //   window.location.protocol &&
  //   window.location.protocol.indexOf('http') !== -1
  // ) {
  //   fb.auth()
  //     .getRedirectResult()
  //     .then((authData) => handleRedirectResult(dispatch, fb, authData))
  //     .catch((e: Error) => {
  //       console.log('Login error', e.message);
  //       Sentry.captureException(e);
  //       dispatch({ type: LOGIN_ERROR, errorMsg: e.message });
  //     });
  // }
};

export const login = async (
  dispatch: (type: AuthAction | ProfileAction) => void,
  fb: FirebaseInstance,
) => {
  dispatch({
    type: LOGIN_START,
  });
  const result = await FirebaseAuthentication.signInWithGoogle();
  const credential = GoogleAuthProvider.credential(result.credential?.idToken);
  signInWithCredential(fb.auth, credential)
    .then((auth) => {
      if (auth && auth.user) {
        const { user } = auth;
        fb._.authUid = user.uid; // eslint-disable-line no-param-reassign

        // watchUserProfile(dispatch, fb);

        trackCompleteRegistration();
        createOrUpdateUserProfile(dispatch, user, fb);
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
      dispatch({ type: LOGIN_ERROR, errorMsg: 'Login failed' });
    });
};

export const logout = (
  dispatch: (type: AuthAction | ProfileAction) => void,
  fb: FirebaseInstance,
) => {
  unWatchUserProfile(fb);
  FirebaseAuthentication.signOut()
    .then(() => signOut(fb.auth))
    .then(() => {
      dispatch({ type: LOGOUT });
      fb._.authUid = null;
    });
};
