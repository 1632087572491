import experimentalFeatureReducer from '../experimental/reducer';
import authReducer from '../feature/auth/reducer';
import dotReducer from '../feature/dots/reducer';
import profileReducer from '../feature/profile/reducer';
import styleQuizReducer from '../feature/style-quiz/reducer';
import quickAnnouncementsReducer from '../qab/reducer';
import { Action, DotAppState } from './types';

// Basic implementation of combineReducers (For use with react-hooks).
// TODO: Need to do error handling carefully.
function combineReducers(reducers: { [key: string]: (action: any, state: any) => any }) {
  const reducerKeys = Object.keys(reducers);

  return function combination(state: DotAppState, action: Action): DotAppState {
    let nextState: DotAppState = state;
    for (let i = 0; i < reducerKeys.length; i += 1) {
      const key = reducerKeys[i];
      const reducer = reducers[key];
      const previousStateForKey = state[key];
      const nextStateForKey = reducer(action, previousStateForKey);
      nextState = { ...nextState, [key]: nextStateForKey };
    }
    return nextState;
  };
}

const rootReducer = combineReducers({
  style_quiz: styleQuizReducer,
  auth: authReducer,
  profile: profileReducer,
  dot: dotReducer,
  experimental: experimentalFeatureReducer,
  quick_announcements: quickAnnouncementsReducer,
});

export default rootReducer;
