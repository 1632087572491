import axios, { AxiosRequestConfig } from 'axios';

import { FirebaseInstance } from '../../app/FirebaseInstance';
import { RequestOrder } from '../../feature/dots/types';

const instance = (() => {
  const baseUrl = (() => {
    switch (process.env.REACT_APP_BUILD_ENV) {
      case 'staging':
        return 'https://staging-api.dotmystyle.com';
      case 'prod':
        return 'https://api.dotmystyle.com';
      default:
        return 'https://dev-api.dotmystyle.com';
    }
  })();

  return axios.create({
    baseURL: baseUrl,
    responseType: 'json',
  });
})();

export const setFirebaseAuthToken = async (fbInstance: FirebaseInstance) => {
  const token = await fbInstance.auth.currentUser?.getIdToken();
  if (token && instance.defaults.headers != null) {
    instance.defaults.headers.common.Authorization = token;
  }
};

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('API request config', config);
    }
    return config;
  },
  (error) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('API error', error);
    }
    return Promise.reject(error);
  },
);

export const createOrderHttp = (order: RequestOrder) => instance.post('/orders', order);

export const verifyStyleFeeOrderPaymentHttp = (
  orderId: string,
  razorpayOrderId: string,
  razorpaySignature: string,
  razorpayPaymentId: string,
) =>
  instance.post(`/orders/${orderId}/payment/verify`, {
    razorpay_order_id: razorpayOrderId,
    razorpay_signature: razorpaySignature,
    razorpay_payment_id: razorpayPaymentId,
  });

export const verifyCheckoutOrderPaymentHttp = (
  orderId: string,
  razorpayOrderId: string,
  razorpaySignature: string,
  razorpayPaymentId: string,
) =>
  instance.post(`/orders/${orderId}/payment/verify_checkout`, {
    razorpay_order_id: razorpayOrderId,
    razorpay_signature: razorpaySignature,
    razorpay_payment_id: razorpayPaymentId,
  });

export const saveStyleNoteHttp = (orderId: string, styleNote: string) =>
  instance.patch(`/orders/${orderId}/update_style_note`, { styling_note: styleNote });

export const cancelOrderHttp = (orderId: string) => instance.patch(`/orders/${orderId}/cancel`);

export const submitItemSelectionHttp = (orderId: string, selection: string[], feedback: string) =>
  instance.patch<any, any, { selection: string[]; feedback: string }>(`orders/${orderId}/preview`, {
    selection,
    feedback,
  });

export const checkoutOrderHttp = (orderId: string, formValues: any) =>
  instance.patch(`/orders/${orderId}/checkout`, formValues);
