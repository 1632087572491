import './App.scss';

import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import React, { lazy, Suspense, useEffect } from 'react';
import { Router, Switch } from 'react-router-dom';

import loadExperimentalFeatures from '../experimental/actions';
import PlayStoreRedirect from '../experimental/PlayStoreRedirect';
import { init } from '../feature/auth/actions';
import BePartner from '../feature/partnership/BePartner';
import loadQuickAnnouncements from '../qab/actions';
import PrivateRoute from '../routes/PrivateRoute';
import Layout from '../shared/components/Layout';
import Loader from '../shared/components/Loader';
import { redirectToPlayStoreKey } from '.';
import { isEmpty, isLoaded, SentryRoute, useStoreContext } from './helpers';

const Login = lazy(() => import('../feature/auth/components/Login'));
const Logout = lazy(() => import('../feature/auth/components/Logout'));
const PrivacyPolicy = lazy(() => import('../feature/complainces/PrivacyPolicy'));
const RefundPolicy = lazy(() => import('../feature/complainces/RefundPolicy'));
const ShippingPolicy = lazy(() => import('../feature/complainces/ShippingPolicy'));
const TermsServices = lazy(() => import('../feature/complainces/TermsServices'));
const ContactUs = lazy(() => import('../feature/contact-us/ContactUs'));
const CheckoutOrder = lazy(() => import('../feature/dots/components/checkout/Checkout'));
const CheckoutConfirmation = lazy(
  () => import('../feature/dots/components/checkout/CheckoutConfirmation'),
);
const CreateOrder = lazy(() => import('../feature/dots/components/CreateOrder'));
const OrderDetails = lazy(() => import('../feature/dots/components/OrderDetails'));
const Preview = lazy(() => import('../feature/dots/components/Preview'));
const ReviewAndConfirm = lazy(() => import('../feature/dots/components/ReviewAndConfirm'));
const Dots = lazy(() => import('../feature/dots/Dots'));
const Faq = lazy(() => import('../feature/faq/Faq'));
const HowItWorks = lazy(() => import('../feature/onboarding/HowItWorks'));
const UserProfile = lazy(() => import('../feature/profile/UserProfile'));
const StyleProfile = lazy(() => import('../feature/style-quiz/StyleProfile'));
const StyleQuizContainer = lazy(() => import('../feature/style-quiz/StyleQuizContainer'));
const Landing = lazy(() => import('../landing_beta/Landing'));
const Fallback = lazy(() => import('../shared/components/Fallback'));
const NotFound = lazy(() => import('../shared/components/NotFound'));
const AboutUs = lazy(() => import('../feature/about-us/AboutUs'));

function AuthIsLoaded({ children }: { children: JSX.Element }) {
  const [state] = useStoreContext();
  if (!isLoaded(state.auth)) {
    return <Loader />;
  }
  return children;
}

function fallbackComponent(error: any, componentStack: any, resetError: any) {
  return <Fallback error={error} componentStack={componentStack} resetError={resetError} />;
}

export const browserHistory = createBrowserHistory();

function App() {
  const [state, dispatch, firebaseInstance] = useStoreContext();
  const isAuthUser = isLoaded(state.auth) && !isEmpty(state.auth);

  useEffect(() => {
    // Load experimental features
    loadExperimentalFeatures(dispatch, firebaseInstance);

    // Initialise the login state
    init(dispatch, firebaseInstance);

    // Load the quick announcements
    loadQuickAnnouncements(dispatch, firebaseInstance);
  }, [firebaseInstance, dispatch]);

  return (
    <div className="App">
      <Suspense fallback={<Loader />}>
        <Router history={browserHistory}>
          <Sentry.ErrorBoundary
            fallback={({ error, componentStack, resetError }) =>
              fallbackComponent(error, componentStack, resetError)
            }
            showDialog
          >
            <AuthIsLoaded>
              <Layout>
                <Switch>
                  <SentryRoute component={Login} path="/login" exact />
                  <SentryRoute component={Landing} path="/" exact />
                  <SentryRoute component={AboutUs} path="/about-us" exact />
                  <SentryRoute component={Faq} path="/faq" exact />
                  <SentryRoute component={ContactUs} path="/contact-us" exact />
                  <SentryRoute component={PrivacyPolicy} path="/support/privacy-policy" exact />
                  <SentryRoute component={TermsServices} path="/support/terms-services" exact />
                  <SentryRoute component={ShippingPolicy} path="/support/shipping-policy" exact />
                  <SentryRoute component={RefundPolicy} path="/support/refund-policy" exact />
                  <PrivateRoute
                    component={StyleQuizContainer}
                    path="/style-quiz"
                    isAuth={isAuthUser}
                  />
                  <PrivateRoute component={Logout} path="/signout" exact isAuth={isAuthUser} />
                  <PrivateRoute component={UserProfile} path="/account" exact isAuth={isAuthUser} />
                  <PrivateRoute
                    component={HowItWorks}
                    path="/onboarding/how-it-works"
                    exact
                    isAuth={isAuthUser}
                  />
                  <PrivateRoute component={StyleProfile} path="/style" exact isAuth={isAuthUser} />
                  <PrivateRoute
                    component={CreateOrder}
                    path="/dots/request"
                    exact
                    isAuth={isAuthUser}
                  />
                  <PrivateRoute
                    component={ReviewAndConfirm}
                    path="/dots/confirm-request/:order_id/pay"
                    exact
                    isAuth={isAuthUser}
                  />
                  <PrivateRoute
                    component={ReviewAndConfirm}
                    exact
                    path="/dots/confirm-request"
                    isAuth={isAuthUser}
                  />
                  <PrivateRoute
                    component={OrderDetails}
                    path="/dots/orders/:id"
                    exact
                    isAuth={isAuthUser}
                  />
                  <PrivateRoute
                    component={CheckoutConfirmation}
                    path="/dots/checkout/:id/confirm"
                    exact
                    isAuth={isAuthUser}
                  />
                  <PrivateRoute
                    component={CheckoutOrder}
                    path="/dots/checkout/:id"
                    exact
                    isAuth={isAuthUser}
                  />
                  <PrivateRoute
                    component={Preview}
                    path="/dots/orders/:id/preview"
                    exact
                    isAuth={isAuthUser}
                  />
                  <PrivateRoute component={Dots} path="/dots" exact isAuth={isAuthUser} />
                  <SentryRoute component={BePartner} path="/partnership" exact />
                  {redirectToPlayStoreKey.map((key) => (
                    <SentryRoute component={PlayStoreRedirect} path={`/${key}`} exact />
                  ))}
                  <SentryRoute component={NotFound} path="/404-not-found" exact />
                  <SentryRoute component={NotFound} />
                </Switch>
              </Layout>
            </AuthIsLoaded>
          </Sentry.ErrorBoundary>
        </Router>
      </Suspense>
    </div>
  );
}

export default Sentry.withProfiler(App);
