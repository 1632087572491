export const LOAD_STYLE_QUIZ = 'LOAD_STYLE_QUIZ';
export const RENDER_STYLE_QUIZ = 'RENDER_QUIZ';
export const UPDATE_QUIZ_RESPONSE = 'UPDATE_QUIZ_RESPONSE';
export const FINISHING_QUESTION_QUIZ = 'FINISHING_QUESTION_QUIZ';
export const FINISH_QUESTION_QUIZ = 'FINISH_QUESTION_QUIZ';

export type RowColWidth =
  | number
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'
  | 'auto';

interface Option {
  key: string;
  value: any;
  image?: {
    source: string;
    height: number;
    padding: string;
  };
}

export interface QuizQuestion {
  question_type: string;
  question: string;
  choices?: Option[];
  field: string;
  id: string;
  control: string;
  suffix?: string;
  is_required: boolean;
}

export interface Transition {
  condition: string;
  transit_to: string;
}

export interface Page {
  default_transit_to: string;
  questions: QuizQuestion[];
  transitions?: Transition[];
}

interface Pages {
  [page_id: string]: Page;
}

export interface Quiz {
  start: string;
  pages: Pages;
}

export interface QuizResponse {
  [key: string]: any;
}

export const emptyQuizResponse: QuizResponse = {
  age_group: '',
  body_type: '',
  waist_size: '',
  shoulder_length: '',
  height_ft: '',
  height_in: '',
  gender: '',
  bottom_size: '',
  top_size: '',
  outfit_type: [],
  preferred_fit: '',
  dislike_colors: [],
  dislike_items: [],
};

export interface Error {
  errorMsg: string;
}

export interface StyleQuizState {
  loading: boolean;
  loaded: boolean;
  error?: Error;
  quiz?: Quiz;
  quiz_response: QuizResponse;
  finishing?: boolean;
  finished?: boolean;
  show_onboarding?: boolean;
}

interface LoadStyleQuizAction {
  type: typeof LOAD_STYLE_QUIZ;
}

interface RenderStyleQuizAction {
  type: typeof RENDER_STYLE_QUIZ;
  payload: Quiz;
}

interface UpdateQuizResponseAction {
  type: typeof UPDATE_QUIZ_RESPONSE;
  payload: [QuizResponse];
}
interface FinishingQuizAction {
  type: typeof FINISHING_QUESTION_QUIZ;
  shouldShowOnboarding: boolean;
}

interface FinishedQuizAction {
  type: typeof FINISH_QUESTION_QUIZ;
}

export type StyleQuizAction =
  | RenderStyleQuizAction
  | LoadStyleQuizAction
  | UpdateQuizResponseAction
  | FinishingQuizAction
  | FinishedQuizAction;
