import {
  AUTH_EMPTY_CHANGE,
  AuthAction,
  LOGIN,
  LOGIN_START,
  LOGOUT,
  UNAUTHORIZED_ERROR,
} from '../auth/types';
import { ProfileAction, ProfileState, SET_PROFILE } from './types';

const profileReducer = (
  action: ProfileAction | AuthAction,
  state: ProfileState = {
    isLoaded: false,
    isEmpty: true,
    profileError: '',
  },
): ProfileState => {
  switch (action.type) {
    case SET_PROFILE:
      if (!action.profile) {
        return {
          ...state,
          isEmpty: true,
          isLoaded: true,
        };
      }
      return {
        ...state,
        user: action.profile,
        isEmpty: false,
        isLoaded: true,
        profileError: null,
      };
    case LOGIN:
    case LOGIN_START:
      return {
        ...state,
        isEmpty: true,
        isLoaded: false,
        profileError: null,
      };
    case LOGOUT:
    case AUTH_EMPTY_CHANGE:
      return { ...state, isLoaded: true, isEmpty: true, profileError: null };
    case UNAUTHORIZED_ERROR:
      return {
        ...state,
        profileError: action.errorMsg,
      };
    default:
      return state;
  }
};

export default profileReducer;
