import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { SentryRoute } from '../app/helpers';

interface IProps {
  component: (props: RouteComponentProps) => JSX.Element | null;
  path: string;
  exact?: boolean;
  isAuth?: boolean;
}

function PrivateRoute(prop: IProps) {
  const { component: Component, isAuth, ...rest } = prop;
  return (
    <SentryRoute
      {...rest}
      render={(props: RouteComponentProps) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
