import { getAnalytics } from 'firebase/analytics';
import { FirebaseOptions, getApp, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

import { FirebaseInstance } from './FirebaseInstance';

const createFirebaseApp = (config: FirebaseOptions) => {
  try {
    return getApp();
  } catch {
    return initializeApp(config);
  }
};

export default function createFirebaseInstance(firebaseConfig: FirebaseOptions): FirebaseInstance {
  const firebaseApp = createFirebaseApp(firebaseConfig);

  const auth = getAuth(firebaseApp);
  const firestore = getFirestore(firebaseApp);
  const analytics = getAnalytics(firebaseApp);

  const firebaseInstance = {
    auth,
    firestore,
    analytics,
    _: {},
  };

  return firebaseInstance;
}
