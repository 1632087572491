import './index.scss';

import * as Sentry from '@sentry/react';
import { FirebaseOptions } from 'firebase/app';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App, { browserHistory } from './app/App';
import StoreProvider from './app/StoreProvider';
import reportWebVitals from './reportWebVitals';

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (process.env.NODE_ENV === 'development') {
  console.log('Firebase config: ', firebaseConfig);
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://a259db0022f641f6abb44c715bc7187d@o4504283007418368.ingest.sentry.io/4504299742167040',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(browserHistory),
      }),
    ],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_RELEASE_VERSION,
  });
}

const rootContainer = document.getElementById('root');
const root = ReactDOM.createRoot(rootContainer as HTMLElement);
root.render(
  <React.StrictMode>
    <StoreProvider firebaseConfig={firebaseConfig}>
      <App />
    </StoreProvider>
  </React.StrictMode>,
);
// if (rootContainer?.hasChildNodes) {
//   hydrate(
//     <React.StrictMode>
//       <StoreProvider firebaseConfig={firebaseConfig}>
//         <App />
//       </StoreProvider>
//     </React.StrictMode>,
//     rootContainer,
//   );
// } else {
//   render(
//     <React.StrictMode>
//       <StoreProvider firebaseConfig={firebaseConfig}>
//         <App />
//       </StoreProvider>
//     </React.StrictMode>,
//     rootContainer,
//   );
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
