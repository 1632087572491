import React from 'react';

function PlayStoreRedirect() {
  React.useEffect(() => {
    window.location.replace(
      'https://play.google.com/store/apps/details?id=com.dotmystyle.android&referrer=utm_source%3Ddotmystyle-web%26utm_medium%3Dinsta',
    );
  }, []);
  return <div />;
}
export default PlayStoreRedirect;
