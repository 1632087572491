import {
  ADD_PRODUCT_TO_PREVIEW_SELECTION,
  DISMISS_STYLE_NOTE_MODAL,
  DotAction,
  DotState,
  REMOVE_PRODUCT_FROM_PREVIEW_SELECTION,
  SET_CANCELLING_ORDER,
  SET_CHECKOUT_COMPLETED_FAILURE,
  SET_CHECKOUT_COMPLETED_SUCCESS,
  SET_CHECKOUT_CONFIRMATION,
  SET_CHECKOUT_IN_PROCESS,
  SET_CHECKOUT_ORDER,
  SET_EMTPY_CHECKOUT_ERROR,
  SET_ORDERS,
  SET_PREVIEW_ORDER,
  SET_PREVIEW_ORDER_ERROR,
  SET_REQUEST_ORDER,
  SET_SAVING_ORDER,
  SET_SHOW_CANCEL_ORDER_MODAL,
  SET_SUBMITTING_PREVIEW_SELECTION,
  SHOW_STYLE_NOTE_MODAL,
  UNSET_REQUEST_ORDER,
  UNSET_SAVING_ORDER,
  UNSET_SHOW_CANCEL_ORDER_MODAL,
  UNSET_SUBMITTING_PREVIEW_SELECTION,
} from './types';

const dotReducer = (
  action: DotAction,
  state: DotState = { isLoaded: false, isEmpty: true, isSavingOrder: false },
): DotState => {
  switch (action.type) {
    case SET_ORDERS: {
      return {
        ...state,
        isLoaded: true,
        isEmpty: false,
        isSavingOrder: false,
        orders: action.orders,
        previewOrder: undefined,
      };
    }
    case SHOW_STYLE_NOTE_MODAL: {
      return {
        ...state,
        orderForStyleNoteModal: action.order,
      };
    }
    case DISMISS_STYLE_NOTE_MODAL: {
      return {
        ...state,
        orderForStyleNoteModal: undefined,
      };
    }
    case SET_REQUEST_ORDER: {
      return {
        ...state,
        isSavingOrder: false,
        requestOrder: action.request.order,
      };
    }
    case UNSET_REQUEST_ORDER: {
      const { requestOrder, ...newState } = state;
      return { ...newState, isSavingOrder: false, errorSavingOrder: action.error };
    }
    case SET_SAVING_ORDER: {
      return {
        ...state,
        isSavingOrder: true,
        errorSavingOrder: undefined,
      };
    }
    case UNSET_SAVING_ORDER: {
      let error;
      if (action.error !== 'modal_dismiss') {
        error = action.error;
      } else {
        error = state.errorSavingOrder;
      }
      return {
        ...state,
        isSavingOrder: false,
        errorSavingOrder: error,
      };
    }
    case SET_CHECKOUT_ORDER: {
      return {
        ...state,
        checkout: {
          isEmpty: false,
          order: action.order,
          error: '',
          isProcessing: false,
        },
      };
    }
    case SET_EMTPY_CHECKOUT_ERROR: {
      return {
        ...state,
        checkout: {
          isEmpty: true,
          error: 'Oops! No order exist with that id or you are not allowed to access it.',
          isProcessing: false,
        },
      };
    }

    case SET_SHOW_CANCEL_ORDER_MODAL: {
      return {
        ...state,
        cancel: {
          is_cancelling: false,
          order_id: action.orderId,
          order_status: action.orderStatus,
        },
      };
    }
    case SET_PREVIEW_ORDER: {
      return {
        ...state,
        previewOrder: {
          isEmpty: false,
          error: undefined,
          order: action.order,
          selection: action.selection,
        },
      };
    }

    case UNSET_SHOW_CANCEL_ORDER_MODAL: {
      return {
        ...state,
        cancel: undefined,
      };
    }

    case SET_CANCELLING_ORDER: {
      if (state.cancel) {
        return {
          ...state,
          cancel: {
            ...state.cancel,
            is_cancelling: true,
          },
        };
      }
      return state;
    }
    case SET_PREVIEW_ORDER_ERROR:
      return {
        ...state,
        previewOrder: {
          isEmpty: true,
          error: action.error,
          order: undefined,
          selection: new Set(),
        },
      };
    case ADD_PRODUCT_TO_PREVIEW_SELECTION: {
      if (state.previewOrder && state.previewOrder.selection.size < 5) {
        const newSelection = state.previewOrder.selection;
        newSelection.add(action.sku);
        return {
          ...state,
          previewOrder: {
            ...state.previewOrder,
            selection: newSelection,
          },
        };
      }
      return state;
    }

    case REMOVE_PRODUCT_FROM_PREVIEW_SELECTION:
      if (state.previewOrder) {
        const newSelection = state.previewOrder.selection;
        newSelection.delete(action.sku);
        return {
          ...state,
          previewOrder: {
            ...state.previewOrder,
            selection: newSelection,
          },
        };
      }
      return state;

    case SET_SUBMITTING_PREVIEW_SELECTION:
      if (state.previewOrder) {
        return {
          ...state,
          previewOrder: {
            ...state.previewOrder,
            submission: {
              isSubmitting: true,
              isCompleted: false,
              error: undefined,
            },
          },
        };
      }
      return state;

    case UNSET_SUBMITTING_PREVIEW_SELECTION:
      if (state.previewOrder) {
        return {
          ...state,
          previewOrder: {
            ...state.previewOrder,
            submission: {
              isSubmitting: false,
              isCompleted: true,
              error: action.error,
            },
          },
        };
      }
      return state;

    case SET_CHECKOUT_CONFIRMATION:
      if (state.checkout) {
        return {
          ...state,
          checkout: {
            ...state.checkout,
            isProcessing: false,
            confirmation: action.confirmation,
            error: '',
          },
        };
      }
      return state;

    case SET_CHECKOUT_IN_PROCESS:
      if (state.checkout) {
        return {
          ...state,
          checkout: {
            ...state.checkout,
            isProcessing: true,
            error: '',
          },
        };
      }
      return state;
    case SET_CHECKOUT_COMPLETED_SUCCESS:
      if (state.checkout) {
        return {
          ...state,
          checkout: undefined,
        };
      }
      return state;
    case SET_CHECKOUT_COMPLETED_FAILURE:
      if (state.checkout) {
        return {
          ...state,
          checkout: {
            ...state.checkout,
            isProcessing: false,
            error: action.error,
          },
        };
      }
      return state;
    default:
      return state;
  }
};

export default dotReducer;
