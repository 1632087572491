import React from 'react';
import { Spinner } from 'react-bootstrap';

function Loader() {
  return (
    <div className="loader-wrapper">
      <Spinner animation="border" role="status" />
      Loading...
    </div>
  );
}

export default Loader;
