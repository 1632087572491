import { ExperimentalAction, ExperimentalState } from '../experimental/types';
import { AuthAction, AuthState } from '../feature/auth/types';
import { DotAction, DotState } from '../feature/dots/types';
import { ProfileAction, ProfileState } from '../feature/profile/types';
import { emptyQuizResponse, StyleQuizAction, StyleQuizState } from '../feature/style-quiz/types';
import { QuickAnnouncement, QuickAnnouncementAction } from '../qab/types';

export type Action =
  | DotAction
  | StyleQuizAction
  | ProfileAction
  | AuthAction
  | ExperimentalAction
  | QuickAnnouncementAction;

export interface DotAppState {
  auth: AuthState;
  dot: DotState;
  style_quiz: StyleQuizState;
  profile: ProfileState;
  experimental: ExperimentalState;
  quick_announcements: QuickAnnouncement[];
  [key: string]: any;
}

export const initState: DotAppState = {
  auth: {
    isLoaded: false,
    isEmpty: true,
    authError: null,
    isInProgress: false,
  },
  style_quiz: {
    loading: false,
    loaded: false,
    quiz_response: emptyQuizResponse,
  },
  dot: {
    isLoaded: false,
    isEmpty: true,
    isSavingOrder: false,
  },
  profile: {
    isLoaded: false,
    isEmpty: true,
    profileError: null,
  },
  experimental: {
    features: {},
  },
  quick_announcements: [],
};
