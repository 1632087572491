import { addDoc, collection } from 'firebase/firestore';

import { FirebaseInstance } from '../../app/FirebaseInstance';
import { NewsLetterReq } from './types';

export default async function saveUserEmail(
  fbInstance: FirebaseInstance,
  requestBody: NewsLetterReq,
): Promise<boolean> {
  return addDoc(collection(fbInstance.firestore, 'newsletter-directory'), requestBody)
    .then(() => true)
    .catch(() => false);
}
