// Actions

import { User } from 'firebase/auth';

// [[ BEGIN ]]
export const LOGIN = 'LOGIN';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR';
export const ERROR = 'ERROR';
export const AUTH_EMPTY_CHANGE = 'AUTH_EMPTY_CHANGE';

interface LoginStartAction {
  type: typeof LOGIN_START;
}

interface LoginAction {
  type: typeof LOGIN;
  auth: User;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface AuthEmptyChange {
  type: typeof AUTH_EMPTY_CHANGE;
}

interface LoginErrorAction {
  type: typeof LOGIN_ERROR;
  errorMsg: string;
}

export interface UnauthorizedErrorAction {
  type: typeof UNAUTHORIZED_ERROR;
  errorMsg: string;
}

export type AuthAction =
  | LoginStartAction
  | LoginAction
  | LoginErrorAction
  | LogoutAction
  | UnauthorizedErrorAction
  | AuthEmptyChange;

// Actions
// [[ END ]]

export interface AuthState {
  isLoaded: boolean;
  isEmpty: boolean;
  auth?: User;
  authError: string | null;
  isInProgress: boolean;
}
