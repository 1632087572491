import * as Sentry from '@sentry/react';
import get from 'lodash/get';
import size from 'lodash/size';
import { useContext } from 'react';
import { Route } from 'react-router-dom';

import { FirebaseInstance } from './FirebaseInstance';
import StoreContext from './StoreContext';
import { Action, DotAppState } from './types';

export function isEmpty(...args: any[]) {
  return !args || !args.length
    ? true
    : args.some((arg) => !(arg && size(arg)) || arg.isEmpty === true);
}

export function isLoaded(...args: any[]) {
  return !args || !args.length
    ? true
    : args.every((arg) => arg !== undefined && get(arg, 'isLoaded') !== false);
}

const rupeeInd = Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 0,
});

export const formatInRupee = (price: number) => rupeeInd.format(price);

// Create Custom Sentry Route component to parameterized some routes.
export const SentryRoute = Sentry.withSentryRouting(Route);
/**
 * Provides type safe access to StoreContext
 * @returns
 */
export const useStoreContext = () =>
  useContext<[DotAppState, (action: Action) => void, FirebaseInstance]>(StoreContext);
