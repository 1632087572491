import { Timestamp } from 'firebase/firestore';

export interface Tracking {
  company: string;
  tracking_number: string;
  tracking_link: string;
}

export interface ShippingInfo {
  address: string;
  city: string;
  customer: string;
  email: string;
  phone: string;
  pin_code: string;
  state: string;
  tracking?: Tracking;
  estimated_delivery: Timestamp;
}

export interface PaymentInfo {
  status: string;
  amount: number;
  transaction_id?: string;
  payment_gateway: string;
  invoice_id: string;
  additional_info: {
    [key: string]: any;
  };
}

export interface Payment {
  styling_fee: PaymentInfo; // Styling fee paid upfront.
  total: PaymentInfo; // Total payment after adjusting the discount and styling fee.
}

export interface BoxBudget {
  top_wear: string;
  bottom_wear: string;
}

export interface StylingNote {
  note: string;
  is_editable: boolean;
}

interface Preview {
  expires_at: Timestamp;
  products: Product[];
  was_revision_requested: boolean;
}

interface Box {
  type: string;
  styling_note: StylingNote;
  budget: BoxBudget;
  preview?: Preview;
}

interface RequestBox {
  type: string;
  styling_note: string;
  budget: BoxBudget;
}

interface RequestShippingInfo {
  address: string;
  city: string;
  customer: string;
  email: string;
  phone: string;
  pin_code: string;
  state: string;
}

export interface RequestOrder {
  box: RequestBox;
  shipping: RequestShippingInfo;
}

export interface Order {
  /**
   * Current status of the order (different from payment status).
   * Can be one of the following:
   * unconfirmed, confirmed, preview_available, preview_confirmed, revision_requested, shipped, delivered, pickup_scheduled, pickup_completed, cancelled or fulfilled.
   *
   * For more information -> https://github.com/sunsergdev/internal-wiki/blob/main/OrderStatus.md
   */
  status: string;

  /**
   * User id associated with this order.
   */
  user_id: string;

  /**
   * Order id for this order (should be different from the order document key in firestore database.)
   */
  order_id: string;

  /**
   * Order key (not to be confused with order id).
   * It is the document key of order in orders collection of firestore.
   */
  order_key: string;

  /**
   * Contains information related to shipping.
   */
  shipping: ShippingInfo;

  /**
   * Payment information which includes both styling fee and total payment.
   */
  payment: Payment;

  /**
   * Contains information related to Box contents like budget and preferences.
   */
  box: Box;

  /**
   * Order timestamp when order was first created.
   */
  created_on: Timestamp;
}

export interface LocalOrder {
  boxType: string;
  topwearBudget: string;
  bottomwearBudget: string;
  customerName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  zipCode: string;
  state: string;
  styleNote?: string;
  isPaymentAttempted: boolean;
  rzp_order_id?: string; // Razorpay order id used to identity an order and pay in razorpay.
  order_id?: string; // Dot order id used to identify in DotMyStyle.
}

export interface Vendor {
  name: string;
  address: string;
}

export interface ProductVariantSize {
  dot_sku: string;
  info: string;
  size: string;
  vendor_sku: string;
}

export interface ProductVariant {
  color_pattern_hex_code?: string;
  color_pattern_name: string;
  color_pattern_url: string;
  size: ProductVariantSize[];
}

export interface Category {
  category: string;
  name: string;
  path: string;
}

export interface Product {
  brand: string;
  category: Category;
  description: string;
  fit: string;
  images: string[];
  material: string[];
  price: {
    mrp: number;
    discounted_price: number;
  };
  id: string;
  name: string;
  specifications: { [key: string]: string };
  vendor: Vendor;
  variants: ProductVariant[];
  user_pick: boolean;
  product_sku: string; // Rename it to selected_sku
}

export interface CheckoutProductWithFeedback {
  product: Product;
  feedback: Record<'keep_return' | 'fitting_cut' | 'style' | 'quality' | 'price' | 'size', string>;
}

export interface Checkout {
  order?: Order;
  isEmpty: boolean;
  error: string;
  confirmation?: {
    keeping: CheckoutProductWithFeedback[];
    returning: CheckoutProductWithFeedback[];
    total: number;
    form_values: any;
  };
  isProcessing: boolean;
}

export const SET_ORDERS = 'SET_ORDERS';
export const SHOW_STYLE_NOTE_MODAL = 'SET_SHOW_STYLE_NOTE_MODAL';
export const DISMISS_STYLE_NOTE_MODAL = 'DISMISS_STYLE_NOTE_MODAL';
export const SET_SHOW_CANCEL_ORDER_MODAL = 'SET_SHOW_CANCEL_ORDER_MODAL';
export const UNSET_SHOW_CANCEL_ORDER_MODAL = 'UNSET_SHOW_CANCEL_ORDER_MODAL';
export const SET_CANCELLING_ORDER = 'SET_CANCELLING_ORDER';
export const SET_REQUEST_ORDER = 'SET_REQUEST_ORDER';
export const UNSET_REQUEST_ORDER = 'UNSET_REQUEST_ORDER';
export const SET_SAVING_ORDER = 'SET_SAVING_ORDER';
export const UNSET_SAVING_ORDER = 'UNSET_SAVING_ORDER';
export const SET_CHECKOUT_ORDER = 'SET_CHECKOUT_ORDER';
export const SET_EMTPY_CHECKOUT_ERROR = 'SET_EMTPY_CHECKOUT_ERROR';
export const SET_CHECKOUT_ERROR = 'SET_CHECKOUT_ERROR';
export const SET_PREVIEW_ORDER = 'SET_ORDER_PREVIEW';
export const ADD_PRODUCT_TO_PREVIEW_SELECTION = 'ADD_PRODUCT_TO_PREVIEW_SELECTION';
export const REMOVE_PRODUCT_FROM_PREVIEW_SELECTION = 'REMOVE_PRODUCT_FROM_PREVIEW_SELECTION';
export const SET_PREVIEW_ORDER_ERROR = 'SET_PREVIEW_ORDER_ERROR';
export const SET_SUBMITTING_PREVIEW_SELECTION = 'SET_SUBMITTING_PREVIEW_SELECTION';
export const UNSET_SUBMITTING_PREVIEW_SELECTION = 'UNSET_SUBMITTING_PREVIEW_SELECTION';
export const SET_CHECKOUT_CONFIRMATION = 'SET_CHECKOUT_CONFIRMATION';
export const SET_CHECKOUT_IN_PROCESS = 'SET_CHECKOUT_IN_PROCESS';
export const SET_CHECKOUT_COMPLETED_SUCCESS = 'SET_CHECKOUT_COMPLETED_SUCCESS';
export const SET_CHECKOUT_COMPLETED_FAILURE = 'SET_CHECKOUT_COMPLETED_FAILURE';

interface SetOrdersAction {
  type: typeof SET_ORDERS;
  orders: Array<Order>;
}

interface ShowStyleNoteModalAction {
  type: typeof SHOW_STYLE_NOTE_MODAL;
  order: Order;
}

interface DismissStyleNoteModalAction {
  type: typeof DISMISS_STYLE_NOTE_MODAL;
}

interface ShowCancelOrderModalAction {
  type: typeof SET_SHOW_CANCEL_ORDER_MODAL;
  orderId: string;
  orderStatus: string;
}

interface DismissCancelOrderModalAction {
  type: typeof UNSET_SHOW_CANCEL_ORDER_MODAL;
}

interface SetCancellingOrder {
  type: typeof SET_CANCELLING_ORDER;
}

interface SetDotRequest {
  type: typeof SET_REQUEST_ORDER;
  request: {
    order: LocalOrder;
    is_existing_order: boolean;
  };
}

interface UnsetDotRequest {
  type: typeof UNSET_REQUEST_ORDER;
  error?: string;
}

interface SetSavingOrderToFirebase {
  type: typeof SET_SAVING_ORDER;
}

interface UnsetSavingOrder {
  type: typeof UNSET_SAVING_ORDER;
  error?: string;
}

interface SetCheckoutOrder {
  type: typeof SET_CHECKOUT_ORDER;
  order: Order;
}

interface SetCheckoutError {
  type: typeof SET_CHECKOUT_ERROR;
  error: string;
}

interface SetEmptyCheckoutError {
  type: typeof SET_EMTPY_CHECKOUT_ERROR;
}

interface SetPreviewOrder {
  type: typeof SET_PREVIEW_ORDER;
  order: Order;
  selection: Set<string>;
}

interface SetPreviewOrderError {
  type: typeof SET_PREVIEW_ORDER_ERROR;
  error: string;
}

interface AddProductToPreviewSelection {
  type: typeof ADD_PRODUCT_TO_PREVIEW_SELECTION;
  sku: string;
}

interface RemoveProductFromPreviewSelection {
  type: typeof REMOVE_PRODUCT_FROM_PREVIEW_SELECTION;
  sku: string;
}

interface SetSubmittingPreviewSelection {
  type: typeof SET_SUBMITTING_PREVIEW_SELECTION;
}

interface UnsetSubmittingPreviewSelection {
  type: typeof UNSET_SUBMITTING_PREVIEW_SELECTION;
  error?: string;
}

interface SetCheckoutConfirmation {
  type: typeof SET_CHECKOUT_CONFIRMATION;
  confirmation: {
    keeping: CheckoutProductWithFeedback[];
    returning: CheckoutProductWithFeedback[];
    total: number;
    form_values: any;
  };
}

interface SetCheckoutInProcess {
  type: typeof SET_CHECKOUT_IN_PROCESS;
}

interface SetCheckoutCompleted {
  type: typeof SET_CHECKOUT_COMPLETED_SUCCESS;
}

interface SetCheckoutFailure {
  type: typeof SET_CHECKOUT_COMPLETED_FAILURE;
  error: string;
}

export type DotAction =
  | SetOrdersAction
  | ShowStyleNoteModalAction
  | DismissStyleNoteModalAction
  | ShowCancelOrderModalAction
  | DismissCancelOrderModalAction
  | SetCancellingOrder
  | SetDotRequest
  | UnsetDotRequest
  | SetSavingOrderToFirebase
  | UnsetSavingOrder
  | SetCheckoutOrder
  | SetEmptyCheckoutError
  | SetCheckoutError
  | SetPreviewOrder
  | SetPreviewOrderError
  | AddProductToPreviewSelection
  | RemoveProductFromPreviewSelection
  | SetSubmittingPreviewSelection
  | UnsetSubmittingPreviewSelection
  | SetCheckoutConfirmation
  | SetCheckoutInProcess
  | SetCheckoutCompleted
  | SetCheckoutFailure;

export interface DotState {
  isLoaded: boolean;
  isEmpty: boolean;
  orders?: Array<Order>;
  requestOrder?: LocalOrder;
  isSavingOrder: boolean;
  errorSavingOrder?: string;
  payingForOrder?: boolean;
  checkout?: Checkout;
  cancel?: {
    order_id: string;
    order_status: string;
    is_cancelling: boolean;
  };
  orderForStyleNoteModal?: Order; // This order is used for data when showing style note modal.
  previewOrder?: {
    order?: Order;
    isEmpty: boolean;
    error?: string;
    selection: Set<string>;
    submission?: {
      isSubmitting: boolean;
      isCompleted: boolean;
      error?: string;
    };
  };
}
