import {
  AUTH_EMPTY_CHANGE,
  AuthAction,
  AuthState,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_START,
  LOGOUT,
  UNAUTHORIZED_ERROR,
} from './types';

const authReducer = (
  action: AuthAction,
  state: AuthState = {
    isLoaded: false,
    isEmpty: true,
    authError: null,
    isInProgress: false,
  },
): AuthState => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        authError: null,
        isInProgress: true,
      };
    case LOGIN: {
      if (!action.auth) {
        return {
          ...state,
          isEmpty: true,
          isLoaded: true,
          isInProgress: false,
          authError: null,
        };
      }
      const { auth } = action;
      return {
        ...state,
        ...auth,
        isEmpty: false,
        isLoaded: true,
        isInProgress: false,
        authError: null,
      };
    }
    case LOGOUT:
    case AUTH_EMPTY_CHANGE:
      return {
        ...state,
        isLoaded: true,
        isEmpty: true,
        authError: null,
        isInProgress: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isLoaded: true,
        isEmpty: true,
        authError: action.errorMsg,
        isInProgress: false,
      };
    case UNAUTHORIZED_ERROR:
      return { ...state, authError: action.errorMsg, isInProgress: false };
    default:
      return state;
  }
};

export default authReducer;
