import './styles.scss';

import React, { useRef } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import customer_loyalty from './assets/customer-loyalty.png';
import customer_service from './assets/customer-service.png';
import folder_management from './assets/folder-management.png';
import megaphone from './assets/megaphone.png';
import onboarding from './assets/onboarding.png';
import partnership_header from './assets/partnership_header.png';
import rules from './assets/rules.png';

function BePartner() {
  const formRef = useRef<null | HTMLDivElement>();

  const scrollToOnboardingForm = () => formRef.current?.scrollIntoView();

  const [ctaButtonAction, ctaButtonLabel] = [scrollToOnboardingForm, 'Become a Partner'];

  return (
    <Container className="row-gap-5 d-flex flex-column mt-5">
      {/* banner section */}
      <Row className="pt-3 row-gap-5" xs={1} md={2}>
        <Col className="align-self-center" xs={12} md={6}>
          <h1 className="display-3 display-lg-2 fw-semibold text-center text-md-start">
            Partner with DotMyStyle
          </h1>
          <p className="lead fw-normal text-center text-md-start mt-3">
            Increase your earnings, grow your loyal customer base and expand your network as part of
            our thriving fashion community of brands, labels and independent boutiques.
          </p>
          <div className="pt-3 pt-md-5 d-flex flex-column">
            <div className="text-center text-md-start">
              <Button
                className="landing-page-cta-btn fw-semibold"
                variant="dark"
                onClick={ctaButtonAction}
              >
                {ctaButtonLabel}
              </Button>
            </div>
          </div>
        </Col>
        <Col className="offset-md-1 align-self-center" md={5} xs={12}>
          <Image src={partnership_header} fluid />
        </Col>
      </Row>
      {/* how dotmystyle works */}
      <Row className="fact-container" xs={1}>
        <Col>
          <h1 className="display-1 fw-bold">70+</h1>
        </Col>
        <Col>
          <h1>brands and labels already joined</h1>
        </Col>
      </Row>

      <Row className="why-join-dms-container row-gap-5">
        <Col xs={12}>
          <h3 className="display-4 text-center fw-semibold">
            Why join DotMyStyle <br /> Partner Program
          </h3>
        </Col>
        <Col xs={12} md={10} className="offset-md-1 px-5 px-md-0">
          <Row xs={1} md={2}>
            <Col className="py-5">
              <div className="text-start py-1">
                <Image src={onboarding} className="why_dms_container_icon" />
              </div>
              <h2 className="text-start why-dms-container-heading py-1">1 Minute Onboarding</h2>
              <p className="text-start lead py-1">
                It just takes one click to get all the details we need. Simple, quick and
                zero-effort.
              </p>
            </Col>
            <Col className="py-5">
              <div className="text-start py-1">
                <Image src={customer_service} className="why_dms_container_icon" />
              </div>
              <h2 className="text-start why-dms-container-heading py-1">
                End to End Customer Support
              </h2>
              <p className="text-start lead py-1">
                We handle all the customer related queries so that you focus on what you enjoy -
                designing new beautiful clothes.{' '}
              </p>
            </Col>
          </Row>
          <Row xs={1} md={2}>
            <Col className="py-5">
              <div className="text-start py-1">
                <Image src={rules} className="why_dms_container_icon" />
              </div>
              <h2 className="text-start why-dms-container-heading py-1">Zero Prerequisites</h2>
              <p className="text-start lead py-1">
                No documents, no min inventory requirements, no article image guidelines.
              </p>
            </Col>
            <Col className="py-5">
              <div className="text-start py-1">
                <Image src={folder_management} className="why_dms_container_icon" />
              </div>
              <h2 className="text-start py-1 why-dms-container-heading">
                No New Management System
              </h2>
              <p className="text-start lead py-1">
                Keep using your existing tools such as Shopify, WooCommerce or Wix.
              </p>
            </Col>
          </Row>
          <Row xs={1} md={2}>
            <Col className="py-5">
              <div className="text-start py-1">
                <Image src={customer_loyalty} className="why_dms_container_icon" />
              </div>
              <h2 className="text-start why-dms-container-heading py-1">
                Increased Customer Loyalty
              </h2>
              <p className="text-start lead py-1">
                We fully support you on your journey to become affluent brand - get dedicated brand
                page with all your social media.
              </p>
            </Col>
            <Col className="py-5">
              <div className="text-start py-1">
                <Image src={megaphone} className="why_dms_container_icon" />
              </div>
              <h2 className="text-start why-dms-container-heading py-1">
                Expert Marketing Services
              </h2>
              <p className="text-start lead py-1">
                Leverage our marketing services dedicated for fashion brands to reach millions of
                users.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="ready-to-partner-container" xs={1}>
        <Col>
          <h1 className="display-4 fw-semibold pb-4">
            Ready to join our
            <br />
            partner program?
          </h1>
          <h5 className="pb-5 fw-normal fs-4">Be part of the future of fashion e-commerce</h5>
          <div className="text-center">
            <Button
              className="landing-page-cta-btn fw-semibold"
              variant="dark"
              onClick={ctaButtonAction}
            >
              Become a Partner
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="help-container" xs={1} md={2}>
        <Col className="offset-md-1" xs={12} md={6}>
          <h1 className="pb-2 text-center text-md-start">Have any doubts or queries?</h1>
          <p className="lead text-center text-md-start">
            Reach us out on Whatsapp or write a mail at{' '}
            <a href="mailto: pratik@dotmystyle.com">pratik@dotmystyle.com</a>
          </p>
        </Col>
        <Col xs={12} md={4} className="align-self-center mt-3 mt-md-0">
          <Row
            className="text-center text-md-end d-flex justify-content-md-end justify-content-center gy-4"
            xs={1}
            xl={2}
          >
            <Col>
              <Button
                className="landing-page-cta-btn fw-semibold w-100"
                variant="dark"
                href="https://api.whatsapp.com/send/?phone=919720354711&text&type=phone_number&app_absent=0"
              >
                Whatsapp
              </Button>
            </Col>
            <Col>
              <Button
                className="landing-page-cta-btn fw-semibold w-100"
                variant="outline-dark"
                href="mailto: pratik@dotmystyle.com"
              >
                Email us
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-5 onboarding-google-form" ref={formRef}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSeFs9FuV4MQJuiZWGd8qZqvBPg3jO3EOjD4DaTcjAEGHdFibg/viewform?embedded=true"
          width="640"
          height="1261"
          title="Brand Onboarding Form"
        >
          Loading…
        </iframe>
      </Row>
    </Container>
  );
}
export default BePartner;
