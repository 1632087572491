// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const trackLead = () => fbq('track', 'Lead');

export const trackCompleteRegistration = () => fbq('track', 'CompleteRegistration');

export const trackPurchase = (data) => fbq('track', 'Purchase', data);

export const trackViewContent = () => fbq('track', 'ViewContent');

export const trackCustom = (title, data = {}) => fbq('trackCustom', title, data);

export const trackInitiateCheckout = (data) => fbq('track', 'InitiateCheckout', data);

export const trackSubmitApplication = () => fbq('track', 'SubmitApplication');
