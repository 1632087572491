import { collection, getDocs } from 'firebase/firestore';

import { FirebaseInstance } from '../app/FirebaseInstance';
import { ExperimentalAction, SET_EXPERIMENTAL_FEATURES } from './types';

export default async function loadExperimentalFeatures(
  dispatch: (action: ExperimentalAction) => void,
  firebaseInstance: FirebaseInstance,
) {
  getDocs(collection(firebaseInstance.firestore, 'experimental')).then((snapshot) => {
    let features: { [key: string]: any } = {};
    snapshot.forEach((doc) => {
      features = { ...features, [doc.id]: doc.data() };
    });
    dispatch({ type: SET_EXPERIMENTAL_FEATURES, features });
  });
}
