export const SET_QUICK_ANNOUNCEMENTS = 'SET_QUICK_ANNOUNCEMENTS';

interface SetQuickAnnouncemnts {
  type: typeof SET_QUICK_ANNOUNCEMENTS;
  announcements: QuickAnnouncement[];
}

export type QuickAnnouncementAction = SetQuickAnnouncemnts;

export interface QuickAnnouncement {
  bg_color: string;
  is_active: boolean;
  announcement_message: string;
  foreground_color: string;
  timeout: number;
}
