import { Quiz } from '../feature/style-quiz/types';
import apple from '../shared/assets/bodyshapes/apple.svg';
import dontKnow from '../shared/assets/bodyshapes/dont_know.svg';
import hourglass from '../shared/assets/bodyshapes/hourglass.svg';
import inverted_triangle from '../shared/assets/bodyshapes/inverted_triangle.svg';
import men_muscular from '../shared/assets/bodyshapes/men_muscular.svg';
import men_oval from '../shared/assets/bodyshapes/men_oval.svg';
import men_rectangle from '../shared/assets/bodyshapes/men_straight.svg';
import men_triangle from '../shared/assets/bodyshapes/men_triangle.svg';
import oval from '../shared/assets/bodyshapes/oval.svg';
import pear from '../shared/assets/bodyshapes/pear.svg';
import rectangle from '../shared/assets/bodyshapes/rectangle.svg';
import abstract from '../shared/assets/patterns/pattern_abstract.png';
import animal from '../shared/assets/patterns/pattern_animal.png';
import checks from '../shared/assets/patterns/pattern_checks.png';
import floral from '../shared/assets/patterns/pattern_floral.png';
import geometric from '../shared/assets/patterns/pattern_geometric.png';
import polka from '../shared/assets/patterns/pattern_polka.png';
import stripes from '../shared/assets/patterns/pattern_stripes.png';

// This is only for testing purpose.
const styleQuiz: Quiz = {
  start: 'gender',
  pages: {
    gender: {
      questions: [
        {
          question_type: 'SINGLE_CHOICE',
          question: 'Choose your style quiz',
          choices: [
            { key: 'WOMEN', value: 'women' },
            { key: 'MEN', value: 'men' },
          ],
          field: 'gender',
          id: 'Q1',
          control: 'single_choice',
          is_required: true,
        },
      ],
      default_transit_to: 'age_group',
    },
    age_group: {
      questions: [
        {
          question_type: 'SINGLE_CHOICE',
          question: 'What is your age group?',
          choices: [
            { key: '18-25', value: '18_to_25' },
            { key: '26-40', value: '26_to_40' },
            { key: '41-60', value: '41_to_60' },
            { key: '60+', value: '60_to_above' },
          ],
          field: 'age_group',
          id: 'Q2',
          control: 'single_choice',
          is_required: true,
        },
      ],
      default_transit_to: 'body_type_female',
      transitions: [
        {
          condition: "response.gender === 'men'",
          transit_to: 'body_type_male',
        },
      ],
    },
    body_type_female: {
      questions: [
        {
          question_type: 'SINGLE_CHOICE',
          question: 'What is your body shape?',
          choices: [
            {
              value: 'straight',
              key: 'Straight (Rectangle)',
              image: { source: rectangle, height: 200, padding: 'px-4 pt-3 pb-0' },
            },
            {
              value: 'hourglass',
              key: 'Hourglass',
              image: { source: hourglass, height: 200, padding: 'px-4 pt-3 pb-0' },
            },
            {
              value: 'pear',
              key: 'Pear',
              image: { source: pear, height: 200, padding: 'px-4 pt-3 pb-0' },
            },
            {
              value: 'apple',
              key: 'Apple',
              image: { source: apple, height: 200, padding: 'px-4 pt-3 pb-0' },
            },
            {
              value: 'inverted_triangle',
              key: 'Inverted Triangle',
              image: { source: inverted_triangle, height: 200, padding: 'px-4 pt-3 pb-0' },
            },
            {
              value: 'oval',
              key: 'Oval Body (Round)',
              image: { source: oval, height: 200, padding: 'px-4 pt-3 pb-0' },
            },
            {
              value: 'dont_know',
              key: 'Add body measurements',
              image: { source: dontKnow, height: 200, padding: 'px-4 pt-3 pb-0' },
            },
          ],
          field: 'body_type',
          id: 'Q3',
          control: 'single_choice',
          is_required: true,
        },
      ],
      default_transit_to: 'body_measurement_female',
    },
    body_type_male: {
      questions: [
        {
          question_type: 'SINGLE_CHOICE',
          question: 'What is your body shape?',
          choices: [
            {
              value: 'straight',
              key: 'Straight (Rectangle)',
              image: { source: men_rectangle, height: 200, padding: 'px-4 pt-1 pb-1' },
            },
            {
              value: 'oval',
              key: 'Oval (Rounded)',
              image: { source: men_oval, height: 200, padding: 'px-4 pt-1 pb-1' },
            },
            {
              value: 'muscular',
              key: 'Muscular',
              image: { source: men_muscular, height: 200, padding: 'px-4 pt-1 pb-1' },
            },
            {
              value: 'triangle',
              key: 'Triangle',
              image: { source: men_triangle, height: 200, padding: 'px-4 pt-1 pb-1' },
            },
          ],
          field: 'body_type',
          id: 'Q4',
          control: 'single_choice',
          is_required: true,
        },
      ],
      default_transit_to: 'body_measurement_male',
    },
    body_measurement_female: {
      questions: [
        {
          question_type: 'number',
          question: 'What is your bust size (in inches)?',
          field: 'bust_size',
          id: 'Q5',
          control: 'input',
          suffix: 'IN.',
          is_required: false,
        },
        {
          question_type: 'number',
          question: 'What is your waist size (in inches)?',
          field: 'waist_size',
          id: 'Q6',
          control: 'input',
          suffix: 'IN.',
          is_required: false,
        },
        {
          question_type: 'number',
          question: 'What is your hip size (in inches)?',
          field: 'hip_size',
          id: 'Q7',
          control: 'input',
          suffix: 'IN.',
          is_required: false,
        },
        {
          question_type: 'number',
          question: 'What is your shoulder length (in inches)?',
          field: 'shoulder_length',
          id: 'Q8',
          control: 'input',
          suffix: 'IN.',
          is_required: false,
        },
      ],
      default_transit_to: 'height_weight',
    },
    body_measurement_male: {
      questions: [
        {
          question_type: 'number',
          question: 'What is your chest size (in inches)?',
          field: 'chest_size',
          id: 'Q9',
          control: 'input',
          suffix: 'IN.',
          is_required: false,
        },
        {
          question_type: 'number',
          question: 'What is your waist size (in inches)?',
          field: 'waist_size',
          id: 'Q10',
          control: 'input',
          suffix: 'IN.',
          is_required: false,
        },
        {
          question_type: 'number',
          question: 'What is your shoulder length (in inches)?',
          field: 'shoulder_length',
          id: 'Q11',
          control: 'input',
          suffix: 'IN.',
          is_required: false,
        },
      ],
      default_transit_to: 'height_weight',
    },
    height_weight: {
      questions: [
        {
          question_type: 'height',
          question: 'How tall are you?',
          field: 'height',
          id: 'Q12',
          control: 'input',
          is_required: true,
        },
        {
          question_type: 'weight',
          question: 'What is your weight?',
          field: 'weight',
          id: 'Q13',
          control: 'input',
          is_required: true,
        },
      ],
      default_transit_to: 'top_size',
    },
    top_size: {
      questions: [
        {
          question_type: 'SINGLE_CHOICE',
          question: 'What is your top/shirt size you more often wear?',
          choices: [
            { key: 'XS', value: 'XS' },
            { key: 'S', value: 'S' },
            { key: 'M', value: 'M' },
            { key: 'L', value: 'L' },
            { key: 'XL', value: 'XL' },
            { key: 'XXL', value: 'XXL' },
          ],
          field: 'top_size',
          id: 'Q14',
          control: 'single_choice',
          is_required: true,
        },
      ],
      default_transit_to: 'bottom_size_female',
      transitions: [
        {
          condition: "response.gender === 'men'",
          transit_to: 'bottom_size_male',
        },
      ],
    },
    bottom_size_female: {
      questions: [
        {
          question_type: 'SINGLE_CHOICE',
          question: 'What is your waist size you more often wear?',
          choices: [
            { key: '24', value: '24' },
            { key: '26', value: '26' },
            { key: '28', value: '28' },
            { key: '30', value: '30' },
            { key: '32', value: '32' },
            { key: '34', value: '34' },
            { key: '36', value: '36' },
            { key: '38', value: '38' },
            { key: '40', value: '40' },
            { key: '42', value: '42' },
            { key: '44', value: '44' },
            { key: '46', value: '46' },
          ],
          field: 'bottom_size',
          id: 'Q15_F',
          control: 'single_choice',
          is_required: true,
        },
      ],
      default_transit_to: 'outfit_type',
    },
    bottom_size_male: {
      questions: [
        {
          question_type: 'SINGLE_CHOICE',
          question: 'What is your waist size you more often wear?',
          choices: [
            { key: '26', value: '26' },
            { key: '28', value: '28' },
            { key: '30', value: '30' },
            { key: '32', value: '32' },
            { key: '34', value: '34' },
            { key: '36', value: '36' },
            { key: '38', value: '38' },
            { key: '40', value: '40' },
            { key: '42', value: '42' },
          ],
          field: 'bottom_size',
          id: 'Q15_M',
          control: 'single_choice',
          is_required: true,
        },
      ],
      default_transit_to: 'outfit_type',
    },
    outfit_type: {
      questions: [
        {
          question_type: 'MULTI_CHOICE',
          question: 'What kind of outfit are you looking for?',
          choices: [
            { key: 'Summer Outfit', value: 'summer_outfit' },
            { key: 'Casual Wear', value: 'casual_wear' },
            { key: 'Denim Look', value: 'denim_look' },
            { key: 'Athleisure', value: 'athleisure' },
            { key: 'Dating', value: 'dating' },
          ],
          field: 'outfit_type',
          id: 'Q16',
          control: 'multiple_choice',
          is_required: false,
        },
      ],
      default_transit_to: 'preferred_fit',
    },
    preferred_fit: {
      questions: [
        {
          question_type: 'SINGLE_CHOICE',
          question: 'What is your preferred fit?',
          choices: [
            { key: 'Slim Fit', value: 'slim_fit' },
            { key: 'Trim Fit', value: 'trim_fit' },
            { key: 'Loose Fit', value: 'loose_fit' },
            { key: 'Anti Fit', value: 'anti_fit' },
          ],
          field: 'preferred_fit',
          id: 'Q17',
          control: 'single_choice',
          is_required: true,
        },
      ],
      default_transit_to: 'dislike_colors',
    },
    dislike_colors: {
      questions: [
        {
          question_type: 'MULTI_CHOICE',
          question: 'Are there any colors/shades you dislike wearing?',
          choices: [
            { key: 'Browns', value: 'brown' },
            { key: 'Green', value: 'green' },
            { key: 'White', value: 'white' },
            { key: 'Red', value: 'red' },
            { key: 'Blue', value: 'blue' },
            { key: 'Black', value: 'black' },
            { key: 'Yellow', value: 'yellow' },
            { key: 'Purple', value: 'purple' },
          ],
          field: 'dislike_colors',
          id: 'Q18',
          control: 'multiple_choice',
          is_required: false,
        },
      ],
      default_transit_to: 'dislike_items_female',
      transitions: [
        {
          condition: "response.gender === 'men'",
          transit_to: 'dislike_items_male',
        },
      ],
    },
    dislike_items_female: {
      questions: [
        {
          question_type: 'MULTI_CHOICE',
          question: 'Which type of items you never want to receive from us?',
          choices: [
            { key: 'Dresses', value: 'dresses' },
            { key: 'Skirts', value: 'skirts' },
            { key: 'Trousers', value: 'trousers' },
            { key: 'Shorts', value: 'shorts' },
          ],
          field: 'dislike_items',
          id: 'Q19_F',
          control: 'multiple_choice',
          is_required: false,
        },
      ],
      default_transit_to: 'dislike_patterns_female',
    },
    dislike_items_male: {
      questions: [
        {
          question_type: 'MULTI_CHOICE',
          question: 'Which type of items you never want to receive from us?',
          choices: [
            { key: 'Shirts', value: 'shirts' },
            { key: 'T-shirts', value: 't_shirts' },
            { key: 'Jacket', value: 'jacket' },
            { key: 'Blazer', value: 'blazer' },
            { key: 'Sweater/Sweatshirts', value: 'sweater_sweatshirts' },
            { key: 'Jeans', value: 'jeans' },
            { key: 'Trousers', value: 'trousers' },
            { key: 'Shorts', value: 'shorts' },
            { key: 'Joggers', value: 'joggers' },
          ],
          field: 'dislike_items',
          id: 'Q19_M',
          control: 'multiple_choice',
          is_required: false,
        },
      ],
      default_transit_to: 'sleeves_preference',
    },
    sleeves_preference: {
      questions: [
        {
          question_type: 'MULTI_CHOICE',
          question: 'Which type of sleeves do you never prefer on a T-shirt?',
          choices: [
            {
              key: 'Half',
              value: 'half',
            },
            {
              key: 'Full',
              value: 'full',
            },
            {
              key: 'Sleeveless',
              value: 'sleeveless',
            },
          ],
          field: 'tshirt_sleeve',
          id: 'Q21',
          control: 'multiple_choice',
          is_required: false,
        },
        {
          question_type: 'MULTI_CHOICE',
          question: 'Which type of sleeves do you never prefer on a Shirt?',
          field: 'shirt_sleeve',
          choices: [
            {
              key: 'Half',
              value: 'half',
            },
            {
              key: 'Full',
              value: 'full',
            },
          ],
          id: 'Q22',
          control: 'multiple_choice',
          is_required: false,
        },
      ],
      default_transit_to: 'dislike_patterns_male',
    },
    dislike_patterns_male: {
      questions: [
        {
          question_type: 'MULTI_CHOICE',
          question: 'Which type of top wear pattern you never want to wear?',
          choices: [
            {
              key: 'Floral',
              value: 'floral',
            },
            {
              key: 'Checks',
              value: 'checks',
            },
            {
              key: 'Stripes',
              value: 'stripes',
            },
            {
              key: 'Graphic',
              value: 'graphics',
            },
          ],
          field: 'dislike_patterns_topwear',
          id: 'Q24',
          control: 'multiple_choice',
          is_required: false,
        },
        {
          question_type: 'MULTI_CHOICE',
          question: 'Which type of bottom wear pattern you never want to wear?',
          choices: [
            {
              key: 'Distressed/Ripped Denims',
              value: 'distressed_denims',
            },
            {
              key: 'Checks',
              value: 'checks',
            },
            {
              key: 'Stripes',
              value: 'stripes',
            },
          ],
          field: 'dislike_patterns_bottomwear',
          id: 'Q23',
          control: 'multiple_choice',
          is_required: false,
        },
      ],
      default_transit_to: 'finish',
    },
    dislike_patterns_female: {
      questions: [
        {
          question_type: 'MULTI_CHOICE',
          question: 'Which type of pattern you never want to wear?',
          choices: [
            {
              key: 'Polka Dots',
              value: 'polka_dots',
              image: { source: polka, padding: 'p-3', height: 120 },
            },
            {
              key: 'Floral',
              value: 'floral',
              image: { source: floral, padding: 'p-3', height: 120 },
            },
            {
              key: 'Checks',
              value: 'checks',
              image: { source: checks, padding: 'p-3', height: 120 },
            },
            {
              key: 'Animal Print',
              value: 'animal_print',
              image: { source: animal, padding: 'p-3', height: 120 },
            },
            {
              key: 'Abstract',
              value: 'abstract_print',
              image: { source: abstract, padding: 'p-3', height: 120 },
            },
            {
              key: 'Stripes',
              value: 'stripes',
              image: { source: stripes, padding: 'p-3', height: 120 },
            },
            {
              key: 'Geometric',
              value: 'geometric',
              image: { source: geometric, padding: 'p-3', height: 120 },
            },
          ],
          field: 'dislike_patterns',
          id: 'Q20',
          control: 'multiple_choice',
          is_required: false,
        },
      ],
      default_transit_to: 'finish',
    },
  },
};

export const redirectToPlayStoreKey = ['iimlucknow'];
export default styleQuiz;
