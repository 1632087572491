import { doc, onSnapshot } from 'firebase/firestore';

import { FirebaseInstance } from '../app/FirebaseInstance';
import { QuickAnnouncement, QuickAnnouncementAction, SET_QUICK_ANNOUNCEMENTS } from './types';

export default function loadQuickAnnouncements(
  dispatch: (type: QuickAnnouncementAction) => void,
  fbInstance: FirebaseInstance,
) {
  return onSnapshot(
    doc(fbInstance.firestore, 'site-features', 'notification_header'),
    (document) => {
      if (document.exists()) {
        const data = document.data()?.notification_list as QuickAnnouncement[];
        dispatch({
          type: SET_QUICK_ANNOUNCEMENTS,
          announcements: data.filter((announcement) => announcement.is_active),
        });
      }
    },
  );
}
