import '../styles/Header.scss';

import React from 'react';
import { Button, Dropdown, Nav, Navbar, NavItem, NavLink } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { isEmpty, isLoaded, useStoreContext } from '../../app/helpers';
import logo from '../assets/logo_dark.svg';
import user from '../assets/user.svg';

function Header() {
  const [state] = useStoreContext();
  const isLoggedIn = isLoaded(state.auth) && !isEmpty(state.auth);
  const isProfileLoaded = isLoaded(state.profile) && !isEmpty(state.profile);

  const path = useLocation().pathname;
  let activeKey: string | number = '';

  const experiments = state.experimental;

  if (path.startsWith('/dots')) {
    activeKey = 'dots';
  } else if (path.startsWith('/style') || path.startsWith('/style-quiz')) {
    activeKey = 'style';
  } else if (path.startsWith('/login')) {
    activeKey = 'login';
  } else {
    activeKey = path;
  }

  return (
    <div className="navbar-container shadow-sm">
      <Navbar bg="light" collapseOnSelect expand="md" variant="light">
        <Navbar.Brand href="/" className="first-child">
          <img alt="DotMyStyle" src={logo} width="100" className="d-inline-block align-top" />{' '}
        </Navbar.Brand>

        <Navbar.Toggle
          className="middle-child"
          aria-controls="responsive-navbar-nav"
          data-bs-target="#responsive-navbar-nav"
        />

        <Navbar.Collapse id="responsive-navbar-nav" className="navsection-last-child">
          <Nav variant="tabs" activeKey={activeKey}>
            {experiments.features.feature_shop_razorpay_kyc?.is_active && (
              <Nav.Item className="ms-1 me-1">
                <Nav.Link className="custom" eventKey="shop" href="https://store.dotmystyle.com">
                  Shop
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item className="ms-1 me-1">
              <Nav.Link className="custom" eventKey="/" href="/">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="ms-1 me-1">
              <Nav.Link className="custom" eventKey="/about-us" href="/about-us">
                About Us
              </Nav.Link>
            </Nav.Item>
            {/* {isStyleQuizCompleted && (
              <Nav.Item className="ms-1 me-1">
                <Nav.Link className="custom" eventKey="style" href="/style">
                  Style
                </Nav.Link>
              </Nav.Item>
            )} */}

            {/* {!isLoggedIn && (
              <Nav.Item className="ms-2 me-2">
                <Nav.Link className="custom" eventKey="login" href="/login">
                  Login
                </Nav.Link>
              </Nav.Item>
            )} */}

            {!isLoggedIn && (
              <Nav.Item className="ms-3 me-2">
                <Button
                  className="header-action-btn"
                  href="https://play.google.com/store/apps/details?id=com.dotmystyle.android&utm_source=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                >
                  Get It on Google Play
                </Button>
              </Nav.Item>
            )}

            {isLoggedIn && (
              <Dropdown as={NavItem} className="ms-1 me-2">
                <Dropdown.Toggle as={NavLink}>
                  <div className="d-inline-flex align-items-center avatar-container">
                    <span className="avatar">
                      {isProfileLoaded ? (
                        state.profile?.user?.name?.charAt(0)
                      ) : (
                        <img className="user_default_avatar" src={user} alt="user" />
                      )}
                    </span>
                    <span className="ms-2 me-1">
                      {isProfileLoaded ? state.profile?.user?.name : 'User'}
                    </span>
                  </div>
                </Dropdown.Toggle>
                {isProfileLoaded && (
                  <Dropdown.Menu>
                    <Dropdown.Item href="/account">Account Settings</Dropdown.Item>
                    <Dropdown.Item href="https://www.dotmystyle.com/faq">Help</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="/signout">Sign Out</Dropdown.Item>
                  </Dropdown.Menu>
                )}
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
