import { createContext } from 'react';

import { FirebaseInstance } from './FirebaseInstance';
import { Action, DotAppState, initState } from './types';

/**
 * @description Context for data store created
 */
const StoreContext = createContext<[DotAppState, (action: Action) => void, FirebaseInstance | any]>(
  [initState, () => {}, {}],
);

export default StoreContext;
