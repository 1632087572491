import '../styles/Footer.scss';

import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { serverTimestamp } from 'firebase/firestore';
import { Formik, FormikState } from 'formik';
import React from 'react';
import { Button, Col, Container, Form, FormControl, InputGroup, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { FirebaseInstance } from '../../app/FirebaseInstance';
import { useStoreContext } from '../../app/helpers';
import saveUserEmail from '../actions/action';
import logo from '../assets/logo_light.svg';

const schema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email required'),
});

async function handleNewsLetterSubmit(
  fbInstance: FirebaseInstance,
  values: any,
  resetForm: (nextState?: Partial<FormikState<{ email: string }>>) => void,
) {
  const r = await saveUserEmail(fbInstance, {
    email: values.email,
    created_on: serverTimestamp(),
  });
  if (r === true) {
    toast.success('Thank you for subscribing to the DotMyStyle newsletter.');
    resetForm();
  } else {
    toast.error('Something went wrong', { autoClose: 3000 });
  }
}

function Footer() {
  const [, , fbInstance] = useStoreContext();

  return (
    <Container className="footer">
      <Row lg={4} className="main-row">
        <Col lg={4} className="columns">
          <a href="/">
            <Row className="logo">
              <img
                alt="DotMyStyle"
                src={logo}
                height="100px"
                className="d-inline-block align-top"
              />{' '}
            </Row>
          </a>
          <Row className="slogan-text">
            <p>Everyone has different style.</p>
          </Row>
          <Row>
            <Col className="social-media-icon">
              <Link
                to={{ pathname: 'https://www.instagram.com/dotmystyle' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} color="white" size="2x" className="icon" />
              </Link>
              <Link
                to={{ pathname: 'https://www.facebook.com/dotmystyle' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} color="white" size="2x" className="icon" />
              </Link>
              <Link
                to={{ pathname: 'https://twitter.com/DotMyStyle' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} color="white" size="2x" className="icon" />
              </Link>
              <Link
                to={{ pathname: 'https://www.linkedin.com/company/dotmystyle/' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} color="white" size="2x" className="icon" />
              </Link>
            </Col>
          </Row>
        </Col>
        <Col lg={2} className="columns">
          <Row>
            <strong className="col-heading">Company</strong>
          </Row>
          <Row>
            <Nav.Link href="/about-us" className="second-col">
              About Us
            </Nav.Link>
          </Row>
          <Row>
            <Nav.Link href="/partnership" className="second-col">
              Be Our Partner
            </Nav.Link>
          </Row>
          <Row>
            <Nav.Link
              href="https://medium.com/dot-labs"
              target="_blank"
              rel="noopener noreferrer"
              className="second-col"
            >
              Blog
            </Nav.Link>
          </Row>
          <Row>
            <Nav.Link href="/contact-us" className="second-col">
              Contact Us
            </Nav.Link>
          </Row>
          <Row>
            <Nav.Link href="/faq" className="second-col">
              FAQ
            </Nav.Link>
          </Row>
        </Col>
        <Col lg={2} className="columns">
          <Row>
            <strong className="col-heading">Support</strong>
          </Row>
          <Row>
            <Nav.Link href="/support/privacy-policy" className="second-col">
              Privacy Policy
            </Nav.Link>
          </Row>
          <Row>
            <Nav.Link href="/support/terms-services" className="second-col">
              Terms of Service
            </Nav.Link>
          </Row>
          <Row>
            <Nav.Link href="/support/shipping-policy" className="second-col">
              Shipping Policy
            </Nav.Link>
          </Row>
          <Row>
            <Nav.Link href="/support/refund-policy" className="second-col">
              Cancel and Refund
            </Nav.Link>
          </Row>
          <Row>
            <Nav.Link href="/support/help" className="second-col">
              Help
            </Nav.Link>
          </Row>
        </Col>
        <Col lg={4} className="columns">
          <Row>
            <strong className="col-heading">Mail me the latest arrival</strong>
          </Row>

          <Formik
            initialValues={{
              email: '',
            }}
            validationSchema={schema}
            onSubmit={(values, actions) => {
              handleNewsLetterSubmit(fbInstance, values, actions.resetForm);
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form onSubmit={handleSubmit}>
                <InputGroup className="mb-3" as="div" hasValidation>
                  <FormControl
                    id="email"
                    name="email"
                    placeholder="Your email address"
                    aria-label="Recipient's email"
                    aria-describedby="basic-email"
                    className="newsletter_input"
                    required
                    type="email"
                    isInvalid={!!errors.email}
                    value={values.email}
                    onChange={handleChange}
                    isValid={touched.email && !errors.email}
                  />
                  <Button
                    className="newsletter_subscribe_btn input-group-append"
                    type="submit"
                    aria-describedby="basic-email"
                  >
                    <FontAwesomeIcon icon={faPaperPlane} className="newsletter_subscribe_btn" />{' '}
                  </Button>
                </InputGroup>
                <Form.Control.Feedback className="text-white">Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid" className="text-white">
                  {errors.email}
                </Form.Control.Feedback>
              </Form>
            )}
          </Formik>
          <p>We send our latest arriavls once a week, every Monday.</p>
        </Col>
      </Row>
      <Row>
        <p className="copyright-text">© 2023 DotMyStyle. All rights reserved</p>
      </Row>
      <ToastContainer position="bottom-right" className="mt-5" newestOnTop autoClose={3000} />
    </Container>
  );
}

export default Footer;
