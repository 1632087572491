import { FirebaseOptions } from 'firebase/app';
import React, { useReducer } from 'react';

import createFirebaseInstance from './createFirebaseInstance';
import rootReducer from './reducer';
import StoreContext from './StoreContext';
import { initState } from './types';

interface StoreProps {
  children: JSX.Element;
  firebaseConfig: FirebaseOptions;
}

// Create a provider for components to consume and subscribe to changes
function StoreProvider(props: StoreProps) {
  const { firebaseConfig } = props;
  const [state, dispatch] = useReducer(rootReducer, initState);

  const fbInstance = React.useMemo(() => {
    const firebaseInstance = createFirebaseInstance(firebaseConfig);
    return firebaseInstance;
  }, [firebaseConfig]);

  const { children } = props;

  return (
    // TODO: We need to separate firebase and store context to avoid this eslint warning.
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StoreContext.Provider value={[state, dispatch, fbInstance]}>{children}</StoreContext.Provider>
  );
}

export default StoreProvider;
