// Model Types
export interface Address {
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface User {
  email: string;
  avatar: string | null;
  id: string;
  name: string;
  provider: string;
  isQuizCompleted: boolean;
  quizResponse: {
    [key: string]: any;
  };
  address?: Address;
  phoneNumber?: string;
}

export interface UpdateUserProfileRequest {
  name: string;
  address: {
    address?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
  phoneNumber?: string;
}

// Action Types
export const SET_PROFILE = 'SET_PROFILE';

interface SetProfile {
  type: typeof SET_PROFILE;
  profile: User | null | undefined;
}

export const GET_STATE_UT_MASTER = 'GET_STATE_UT_MASTER';

interface GetStateUTMaster {
  type: typeof GET_STATE_UT_MASTER;
}
export type ProfileAction = SetProfile;

export type GetStateUT = GetStateUTMaster;

// Profile State
export interface ProfileState {
  isLoaded: boolean;
  isEmpty: boolean;
  user?: User;
  profileError: string | null;
}
