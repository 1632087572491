import { ExperimentalAction, ExperimentalState, SET_EXPERIMENTAL_FEATURES } from './types';

const experimentalFeatureReducer = (
  action: ExperimentalAction,
  state: ExperimentalState = { features: {} },
): ExperimentalState => {
  switch (action.type) {
    case SET_EXPERIMENTAL_FEATURES:
      return {
        features: action.features,
      };
    default:
      return state;
  }
};

export default experimentalFeatureReducer;
