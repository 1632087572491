export const SET_EXPERIMENTAL_FEATURES = 'SET_EXPERIMENTAL_FEATURES';

interface SetExperimentalFeatures {
  type: typeof SET_EXPERIMENTAL_FEATURES;
  features: { [key: string]: any };
}

export type ExperimentalAction = SetExperimentalFeatures;

export interface ExperimentalState {
  features: { [key: string]: any };
}
